exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-area-bump-index-tsx": () => import("./../../../src/pages/area-bump/index.tsx" /* webpackChunkName: "component---src-pages-area-bump-index-tsx" */),
  "component---src-pages-bar-api-tsx": () => import("./../../../src/pages/bar/api.tsx" /* webpackChunkName: "component---src-pages-bar-api-tsx" */),
  "component---src-pages-bar-canvas-js": () => import("./../../../src/pages/bar/canvas.js" /* webpackChunkName: "component---src-pages-bar-canvas-js" */),
  "component---src-pages-bar-index-js": () => import("./../../../src/pages/bar/index.js" /* webpackChunkName: "component---src-pages-bar-index-js" */),
  "component---src-pages-boxplot-index-tsx": () => import("./../../../src/pages/boxplot/index.tsx" /* webpackChunkName: "component---src-pages-boxplot-index-tsx" */),
  "component---src-pages-bullet-index-js": () => import("./../../../src/pages/bullet/index.js" /* webpackChunkName: "component---src-pages-bullet-index-js" */),
  "component---src-pages-bump-index-tsx": () => import("./../../../src/pages/bump/index.tsx" /* webpackChunkName: "component---src-pages-bump-index-tsx" */),
  "component---src-pages-calendar-api-tsx": () => import("./../../../src/pages/calendar/api.tsx" /* webpackChunkName: "component---src-pages-calendar-api-tsx" */),
  "component---src-pages-calendar-canvas-js": () => import("./../../../src/pages/calendar/canvas.js" /* webpackChunkName: "component---src-pages-calendar-canvas-js" */),
  "component---src-pages-calendar-index-js": () => import("./../../../src/pages/calendar/index.js" /* webpackChunkName: "component---src-pages-calendar-index-js" */),
  "component---src-pages-chord-api-tsx": () => import("./../../../src/pages/chord/api.tsx" /* webpackChunkName: "component---src-pages-chord-api-tsx" */),
  "component---src-pages-chord-canvas-tsx": () => import("./../../../src/pages/chord/canvas.tsx" /* webpackChunkName: "component---src-pages-chord-canvas-tsx" */),
  "component---src-pages-chord-index-tsx": () => import("./../../../src/pages/chord/index.tsx" /* webpackChunkName: "component---src-pages-chord-index-tsx" */),
  "component---src-pages-choropleth-canvas-tsx": () => import("./../../../src/pages/choropleth/canvas.tsx" /* webpackChunkName: "component---src-pages-choropleth-canvas-tsx" */),
  "component---src-pages-choropleth-index-tsx": () => import("./../../../src/pages/choropleth/index.tsx" /* webpackChunkName: "component---src-pages-choropleth-index-tsx" */),
  "component---src-pages-circle-packing-api-tsx": () => import("./../../../src/pages/circle-packing/api.tsx" /* webpackChunkName: "component---src-pages-circle-packing-api-tsx" */),
  "component---src-pages-circle-packing-canvas-js": () => import("./../../../src/pages/circle-packing/canvas.js" /* webpackChunkName: "component---src-pages-circle-packing-canvas-js" */),
  "component---src-pages-circle-packing-html-js": () => import("./../../../src/pages/circle-packing/html.js" /* webpackChunkName: "component---src-pages-circle-packing-html-js" */),
  "component---src-pages-circle-packing-index-js": () => import("./../../../src/pages/circle-packing/index.js" /* webpackChunkName: "component---src-pages-circle-packing-index-js" */),
  "component---src-pages-components-tsx": () => import("./../../../src/pages/components.tsx" /* webpackChunkName: "component---src-pages-components-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-funnel-index-tsx": () => import("./../../../src/pages/funnel/index.tsx" /* webpackChunkName: "component---src-pages-funnel-index-tsx" */),
  "component---src-pages-geomap-canvas-js": () => import("./../../../src/pages/geomap/canvas.js" /* webpackChunkName: "component---src-pages-geomap-canvas-js" */),
  "component---src-pages-geomap-index-js": () => import("./../../../src/pages/geomap/index.js" /* webpackChunkName: "component---src-pages-geomap-index-js" */),
  "component---src-pages-guides-axes-tsx": () => import("./../../../src/pages/guides/axes.tsx" /* webpackChunkName: "component---src-pages-guides-axes-tsx" */),
  "component---src-pages-guides-colors-tsx": () => import("./../../../src/pages/guides/colors.tsx" /* webpackChunkName: "component---src-pages-guides-colors-tsx" */),
  "component---src-pages-guides-gradients-tsx": () => import("./../../../src/pages/guides/gradients.tsx" /* webpackChunkName: "component---src-pages-guides-gradients-tsx" */),
  "component---src-pages-guides-legends-tsx": () => import("./../../../src/pages/guides/legends.tsx" /* webpackChunkName: "component---src-pages-guides-legends-tsx" */),
  "component---src-pages-guides-patterns-tsx": () => import("./../../../src/pages/guides/patterns.tsx" /* webpackChunkName: "component---src-pages-guides-patterns-tsx" */),
  "component---src-pages-guides-theming-tsx": () => import("./../../../src/pages/guides/theming.tsx" /* webpackChunkName: "component---src-pages-guides-theming-tsx" */),
  "component---src-pages-heatmap-api-tsx": () => import("./../../../src/pages/heatmap/api.tsx" /* webpackChunkName: "component---src-pages-heatmap-api-tsx" */),
  "component---src-pages-heatmap-canvas-tsx": () => import("./../../../src/pages/heatmap/canvas.tsx" /* webpackChunkName: "component---src-pages-heatmap-canvas-tsx" */),
  "component---src-pages-heatmap-index-tsx": () => import("./../../../src/pages/heatmap/index.tsx" /* webpackChunkName: "component---src-pages-heatmap-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internal-home-demos-tsx": () => import("./../../../src/pages/internal/home-demos.tsx" /* webpackChunkName: "component---src-pages-internal-home-demos-tsx" */),
  "component---src-pages-internal-icons-tsx": () => import("./../../../src/pages/internal/icons.tsx" /* webpackChunkName: "component---src-pages-internal-icons-tsx" */),
  "component---src-pages-line-api-tsx": () => import("./../../../src/pages/line/api.tsx" /* webpackChunkName: "component---src-pages-line-api-tsx" */),
  "component---src-pages-line-canvas-js": () => import("./../../../src/pages/line/canvas.js" /* webpackChunkName: "component---src-pages-line-canvas-js" */),
  "component---src-pages-line-index-js": () => import("./../../../src/pages/line/index.js" /* webpackChunkName: "component---src-pages-line-index-js" */),
  "component---src-pages-marimekko-index-tsx": () => import("./../../../src/pages/marimekko/index.tsx" /* webpackChunkName: "component---src-pages-marimekko-index-tsx" */),
  "component---src-pages-network-canvas-tsx": () => import("./../../../src/pages/network/canvas.tsx" /* webpackChunkName: "component---src-pages-network-canvas-tsx" */),
  "component---src-pages-network-index-tsx": () => import("./../../../src/pages/network/index.tsx" /* webpackChunkName: "component---src-pages-network-index-tsx" */),
  "component---src-pages-parallel-coordinates-canvas-tsx": () => import("./../../../src/pages/parallel-coordinates/canvas.tsx" /* webpackChunkName: "component---src-pages-parallel-coordinates-canvas-tsx" */),
  "component---src-pages-parallel-coordinates-index-tsx": () => import("./../../../src/pages/parallel-coordinates/index.tsx" /* webpackChunkName: "component---src-pages-parallel-coordinates-index-tsx" */),
  "component---src-pages-pie-api-tsx": () => import("./../../../src/pages/pie/api.tsx" /* webpackChunkName: "component---src-pages-pie-api-tsx" */),
  "component---src-pages-pie-canvas-js": () => import("./../../../src/pages/pie/canvas.js" /* webpackChunkName: "component---src-pages-pie-canvas-js" */),
  "component---src-pages-pie-index-js": () => import("./../../../src/pages/pie/index.js" /* webpackChunkName: "component---src-pages-pie-index-js" */),
  "component---src-pages-radar-api-tsx": () => import("./../../../src/pages/radar/api.tsx" /* webpackChunkName: "component---src-pages-radar-api-tsx" */),
  "component---src-pages-radar-index-tsx": () => import("./../../../src/pages/radar/index.tsx" /* webpackChunkName: "component---src-pages-radar-index-tsx" */),
  "component---src-pages-radial-bar-index-tsx": () => import("./../../../src/pages/radial-bar/index.tsx" /* webpackChunkName: "component---src-pages-radial-bar-index-tsx" */),
  "component---src-pages-references-tsx": () => import("./../../../src/pages/references.tsx" /* webpackChunkName: "component---src-pages-references-tsx" */),
  "component---src-pages-sankey-api-tsx": () => import("./../../../src/pages/sankey/api.tsx" /* webpackChunkName: "component---src-pages-sankey-api-tsx" */),
  "component---src-pages-sankey-index-tsx": () => import("./../../../src/pages/sankey/index.tsx" /* webpackChunkName: "component---src-pages-sankey-index-tsx" */),
  "component---src-pages-scatterplot-canvas-js": () => import("./../../../src/pages/scatterplot/canvas.js" /* webpackChunkName: "component---src-pages-scatterplot-canvas-js" */),
  "component---src-pages-scatterplot-index-js": () => import("./../../../src/pages/scatterplot/index.js" /* webpackChunkName: "component---src-pages-scatterplot-index-js" */),
  "component---src-pages-stream-index-js": () => import("./../../../src/pages/stream/index.js" /* webpackChunkName: "component---src-pages-stream-index-js" */),
  "component---src-pages-sunburst-api-tsx": () => import("./../../../src/pages/sunburst/api.tsx" /* webpackChunkName: "component---src-pages-sunburst-api-tsx" */),
  "component---src-pages-sunburst-index-js": () => import("./../../../src/pages/sunburst/index.js" /* webpackChunkName: "component---src-pages-sunburst-index-js" */),
  "component---src-pages-swarmplot-canvas-js": () => import("./../../../src/pages/swarmplot/canvas.js" /* webpackChunkName: "component---src-pages-swarmplot-canvas-js" */),
  "component---src-pages-swarmplot-index-js": () => import("./../../../src/pages/swarmplot/index.js" /* webpackChunkName: "component---src-pages-swarmplot-index-js" */),
  "component---src-pages-time-range-index-js": () => import("./../../../src/pages/time-range/index.js" /* webpackChunkName: "component---src-pages-time-range-index-js" */),
  "component---src-pages-tree-canvas-tsx": () => import("./../../../src/pages/tree/canvas.tsx" /* webpackChunkName: "component---src-pages-tree-canvas-tsx" */),
  "component---src-pages-tree-index-tsx": () => import("./../../../src/pages/tree/index.tsx" /* webpackChunkName: "component---src-pages-tree-index-tsx" */),
  "component---src-pages-treemap-api-tsx": () => import("./../../../src/pages/treemap/api.tsx" /* webpackChunkName: "component---src-pages-treemap-api-tsx" */),
  "component---src-pages-treemap-canvas-tsx": () => import("./../../../src/pages/treemap/canvas.tsx" /* webpackChunkName: "component---src-pages-treemap-canvas-tsx" */),
  "component---src-pages-treemap-html-tsx": () => import("./../../../src/pages/treemap/html.tsx" /* webpackChunkName: "component---src-pages-treemap-html-tsx" */),
  "component---src-pages-treemap-index-tsx": () => import("./../../../src/pages/treemap/index.tsx" /* webpackChunkName: "component---src-pages-treemap-index-tsx" */),
  "component---src-pages-voronoi-index-js": () => import("./../../../src/pages/voronoi/index.js" /* webpackChunkName: "component---src-pages-voronoi-index-js" */),
  "component---src-pages-waffle-canvas-tsx": () => import("./../../../src/pages/waffle/canvas.tsx" /* webpackChunkName: "component---src-pages-waffle-canvas-tsx" */),
  "component---src-pages-waffle-html-tsx": () => import("./../../../src/pages/waffle/html.tsx" /* webpackChunkName: "component---src-pages-waffle-html-tsx" */),
  "component---src-pages-waffle-index-tsx": () => import("./../../../src/pages/waffle/index.tsx" /* webpackChunkName: "component---src-pages-waffle-index-tsx" */)
}

